import axios from 'axios'
import UserSubscriptions, { ProductData } from '@/entities/UserSubscriptions'
import { Mapper } from '@/entities/api/Page'
import { SS_SOURCES, SS_UTMS } from '@/const'

interface UTMS {
  utm_source?: string
  utm_medium?: string
  utm_campaing?: string
  utm_type?: string
}

export class NotificationCenterService {
  private baseUrl: string

  private endpoint = {
    customer: '/customers',
    subscriptions: '/subscriptions',
  }

  constructor() {
    this.baseUrl = process.env.BIS_URL
  }

  getCustomerSubscriptions(email: string): Promise<void> {
    const emailEncrypted: string = btoa(email)

    return new Promise<void>((resolve, reject) => {
      axios
        .get(`${this.baseUrl}${this.endpoint.customer}/${emailEncrypted}`)
        .then(
          (r) => {
            if (r && r.status === 200) {
              return resolve()
            }
            return reject(r)
          },
          (r) => {
            return reject(r)
          }
        )
    })
  }

  subscribeCustomerToContext(data: {
    email: string
    context: string
    locale: string
    source: string
  }): Promise<void> {
    const emailEncrypted: string = btoa(data.email)

    return new Promise<void>((resolve, reject) => {
      axios
        .post(
          `${this.baseUrl}${this.endpoint.customer}/${emailEncrypted}/subscribe?locale=${data.locale}&contexte=${data.context}&source=${data.source}`
        )
        .then(
          (r) => {
            if (r && r.status === 200) {
              return resolve()
            }
            return reject(r)
          },
          (r) => {
            return reject(r)
          }
        )
    })
  }

  getProductsSubscriptions(email: string): Promise<UserSubscriptions> {
    const emailEncrypted: string = btoa(email)

    const query = new URL(
      `${this.baseUrl}${this.endpoint.subscriptions}/${emailEncrypted}`
    )

    return new Promise<UserSubscriptions>((resolve, reject) => {
      axios.get(query.toString()).then(
        (r) => {
          if (r && r.status === 200) {
            const res = mapper.map(r.data.data)
            return resolve(res)
          }
          reject(r)
        },
        (r) => {
          if (r.message.includes('404')) {
            resolve(new UserSubscriptions({ email }))
            return
          }
          reject(r.message)
        }
      )
    })
  }

  subscribeToProduct(
    email: string,
    product: ProductData,
    lang: string,
    context: string
  ): Promise<void> {
    const emailEncrypted: string = btoa(email)
    const locale = lang.substring(0, 2)
    const contextParam = context === 'h' ? 'homme' : 'femme'

    const utms = window.sessionStorage.getItem(SS_UTMS)
    const sources = window.sessionStorage.getItem(SS_SOURCES)

    let utmsData: { [key: string]: string } = {}

    if (utms) {
      utmsData = JSON.parse(utms)
    }

    if (sources) {
      const sourcesData = JSON.parse(sources)
      utmsData = {
        ...utmsData,
        ...sourcesData,
      }
    }

    const query = new URL(
      `${this.baseUrl}${this.endpoint.subscriptions}/${emailEncrypted}/subscribe?locale=${locale}&contexte=${contextParam}`
    )

    return this.updateProductSubscription(query, product, utmsData)
  }

  unsubscribeToProduct(email: string, product: ProductData): Promise<void> {
    const emailEncrypted: string = btoa(email)

    const query = new URL(
      `${this.baseUrl}${this.endpoint.subscriptions}/${emailEncrypted}/unsubscribe`
    )

    return this.updateProductSubscription(query, product)
  }

  private updateProductSubscription(
    query: URL,
    body: ProductData,
    utmsDatas?: UTMS
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios.post(query.toString(), { ...body, ...utmsDatas }).then(
        (r) => {
          if (r && r.status === 200) {
            return resolve()
          }
          reject(r.data)
        },
        (r) => {
          reject(r.message)
        }
      )
    })
  }
}
class UserSubscriptionsMapper implements Mapper<UserSubscriptions> {
  map(obj: any): UserSubscriptions {
    return new UserSubscriptions(obj)
  }
}
const mapper = new UserSubscriptionsMapper()

export default new NotificationCenterService()
