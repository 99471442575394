import Vue from 'vue'
import VueMatomo from 'vue-matomo'

export default (ctx) => {
  if (ctx.nuxtState.layout === 'slice-machine') {
    return
  }

  Vue.use(VueMatomo, {
    host: process.env.FORSETI_URI,
    siteId: 1,
    trackInitialView: false,
    disableCookies: true,
    debug: false,
  })
}
