import axios from 'axios'

import checkoutCreateMutation from './graphql/checkoutCreateMutation'
import checkoutNodeQuery from './graphql/checkoutNodeQuery'
import checkoutLineItemsAddMutation from './graphql/checkoutLineItemsAddMutation'
import checkoutLineItemsUpdateMutation from './graphql/checkoutLineItemsUpdateMutation'
import checkoutLineItemsRemoveMutation from './graphql/checkoutLineItemsRemoveMutation'

import { Mapper } from '@/entities/api/Page'

import Cart from '@/entities/cart/Cart'

class CheckoutService {
  private headers: Object
  private baseUrl: string

  constructor() {
    if (!process.env.SHOPIFY_URL) {
      throw new Error('Missing env var SHOPIFY_URL')
    }
    if (!process.env.SHOPIFY_API_VERSION) {
      throw new Error('Missing env var SHOPIFY_API_VERSION')
    }
    if (!process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN) {
      throw new Error('Missing env var SHOPIFY_STOREFRONT_ACCESS_TOKEN')
    }

    this.baseUrl = `${process.env.SHOPIFY_URL}/api/${process.env.SHOPIFY_API_VERSION}/graphql.json`
    this.headers = {
      'X-Shopify-Storefront-Access-Token': `${process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN}`,
      Accept: 'application/json',
    }
  }

  /**
   * Create a shopify checkout
   * @param {Object} [input] An input object containing zero or more of:
   *   @param {String} [input.email] An email connected to the checkout.
   *   @param {Object[]} [input.lineItems] A list of line items in the checkout. See the {@link https://help.shopify.com/api/storefront-api/reference/input-object/checkoutlineiteminput|Storefront API reference} for valid input fields for each line item.
   *   @param {Object} [input.shippingAddress] A shipping address. See the {@link https://help.shopify.com/api/storefront-api/reference/input-object/mailingaddressinput|Storefront API reference} for valid input fields.
   *   @param {String} [input.note] A note for the checkout.
   *   @param {Object[]} [input.customAttributes] A list of custom attributes for the checkout. See the {@link https://help.shopify.com/api/storefront-api/reference/input-object/attributeinput|Storefront API reference} for valid input fields.
   *   @param {String} [input.presentmentCurrencyCode ] A presentment currency code. See the {@link https://help.shopify.com/en/api/storefront-api/reference/enum/currencycode|Storefront API reference} for valid currency code values.
   * @return {Promise<Cart>} A promise resolving with the created checkout.
   */
  createCheckout(input: Object, lang: String) {
    return axios
      .post(
        this.baseUrl,
        {
          query: checkoutCreateMutation,
          variables: {
            input,
          },
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            return Promise.reject(
              new Error(JSON.stringify(response.data.errors))
            )
          }

          return mapper.map(response.data.data.checkoutCreate.checkout)
        }
      })
  }

  /**
   * Fetches a checkout by ID.
   *
   * @example
   * api.fetch('FlZj9rZXlN5MDY4ZDFiZTUyZTUwNTE2MDNhZjg=').then((checkout) => {
   *   // Do something with the checkout
   * });
   *
   * @param {string} id The id of the checkout to fetch.
   * @return {Promise<Cart>} A promise resolving with a `GraphModel` of the checkout.
   */
  fetch(id: string, lang: string) {
    return axios
      .post(
        this.baseUrl,
        {
          query: checkoutNodeQuery,
          variables: {
            id,
          },
        },

        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            return Promise.reject(
              new Error(JSON.stringify(response.data.errors))
            )
          }

          return mapper.map(response.data.data.node)
        }
      })
  }

  addAttributes(
    checkoutId: string,
    attribute: { key: string; value: string },
    lang: string
  ) {
    return axios
      .post(
        this.baseUrl,
        {
          query: `mutation checkoutAttributesUpdateV2($checkoutId: ID! , $input: CheckoutAttributesUpdateV2Input!) {
        checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
          checkout {
            id
            customAttributes {
              key,
              value
            }
          }
          checkoutUserErrors {
            code
          }
        }
      }`,
          variables: {
            checkoutId,
            input: {
              customAttributes: [attribute],
            },
          },
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            return Promise.reject(
              new Error(JSON.stringify(response.data.errors))
            )
          }

          if (
            response.data.data.checkoutAttributesUpdateV2.checkoutUserErrors
              .length > 0
          ) {
            return Promise.reject(
              new Error(
                response.data.data.checkoutAttributesUpdateV2.checkoutUserErrors[0]
              )
            )
          }

          return response.data.data.checkoutAttributesUpdateV2.checkout
        }
      })
  }

  /**
   * Adds line items to an existing checkout.
   *
   * @example
   * const checkoutId = 'Z2lkOi8vc2hvcGlmeS9DaGVja291dC9kMTZmM2EzMDM4Yjc4N=';
   * const lineItems = [{variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yOTEwNjAyMjc5Mg==', quantity: 5}];
   *
   * api.addLineItems(checkoutId, lineItems).then((checkout) => {
   *   // Do something with the updated checkout
   * });
   *
   * @param {String} checkoutId The ID of the checkout to add line items to.
   * @param {Object[]} lineItems A list of line items to add to the checkout. See the {@link https://help.shopify.com/api/storefront-api/reference/input-object/checkoutlineiteminput|Storefront API reference} for valid input fields for each line item.
   * @return {Promise|GraphModel} A promise resolving with the updated checkout.
   */
  addLineItems(checkoutId: string, lineItems: Object[], lang: String) {
    return axios
      .post(
        this.baseUrl,
        {
          query: checkoutLineItemsAddMutation,
          variables: {
            checkoutId,
            lineItems,
          },
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            return Promise.reject(
              new Error(JSON.stringify(response.data.errors))
            )
          }
        }

        return mapper.map(response.data.data.checkoutLineItemsAdd.checkout)
      })
  }

  /**
   * Updates line items on an existing checkout.
   *
   * @example
   * const checkoutId = 'Z2lkOi8vc2hvcGlmeS9DaGVja291dC9kMTZmM2EzMDM4Yjc4N=';
   * const lineItems = [
   *   {
   *     id: 'TViZGE5Y2U1ZDFhY2FiMmM2YT9rZXk9NTc2YjBhODcwNWIxYzg0YjE5ZjRmZGQ5NjczNGVkZGU=',
   *     quantity: 5,
   *     variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yOTEwNjAyMjc5Mg=='
   *   }
   * ];
   *
   * api.updateLineItems(checkoutId, lineItems).then(checkout => {
   *   // Do something with the updated checkout
   * });
   *
   * @param {String} checkoutId The ID of the checkout to update a line item on.
   * @param {Object[]} lineItems A list of line item information to update. See the {@link https://help.shopify.com/api/storefront-api/reference/input-object/checkoutlineitemupdateinput|Storefront API reference} for valid input fields for each line item.
   * @return {Promise<Cart>} A promise resolving with the updated checkout.
   */
  updateLineItems(checkoutId: string, lineItems: Object[], lang: String) {
    return axios
      .post(
        this.baseUrl,
        {
          query: checkoutLineItemsUpdateMutation,
          variables: {
            checkoutId,
            lineItems,
          },
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            return Promise.reject(
              new Error(JSON.stringify(response.data.errors))
            )
          }
        }

        return mapper.map(response.data.data.checkoutLineItemsUpdate.checkout)
      })
  }

  removeLineItems(checkoutId: string, lineItemIds: string[], lang: String) {
    return axios
      .post(
        this.baseUrl,
        {
          query: checkoutLineItemsRemoveMutation,
          variables: {
            checkoutId,
            lineItemIds,
          },
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            return Promise.reject(
              new Error(JSON.stringify(response.data.errors))
            )
          }
        }

        return mapper.map(response.data.data.checkoutLineItemsRemove.checkout)
      })
  }
}

class CartMapper implements Mapper<Cart> {
  map(obj: any): Cart {
    return new Cart(obj)
  }
}
const mapper = new CartMapper()

export default new CheckoutService()
