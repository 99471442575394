import Attribute from './Attribute'
import CartItem from '@/entities/cart/CartItem'

import { CURRENCY } from '@/const'

export default class Cart {
  checkoutId: string | null = null
  items: CartItem[] = []
  subTotalPrice = 0
  totalPrice = 0
  webUrl = ''
  attributes: Attribute[] = []
  isCompleted = false
  currency: CURRENCY = CURRENCY.EUR

  constructor(data: any) {
    if (!data) {
      return
    }

    if (data.id) {
      this.checkoutId = data.id
    }

    if (data.lineItems && data.lineItems.edges) {
      data.lineItems.edges.forEach((e: any) => {
        this.items.push(new CartItem(e.node))
      })
    }

    if (data.webUrl) {
      this.webUrl = data.webUrl
    }

    if (data.orderStatusUrl) {
      this.isCompleted = true
    }

    this.subTotalPrice = data.lineItemsSubtotalPrice
      ? parseInt(data.lineItemsSubtotalPrice.amount || 0)
      : 0
    this.totalPrice = parseInt(data.totalPrice || 0)
    this.currency = data.currencyCode || 'EUR'

    if (data.customAttributes && data.customAttributes.length > 0) {
      this.setAttributes(
        data.customAttributes.map(
          (a: { key: string; value: string }) => new Attribute(a)
        )
      )
    }
  }

  get nbItems() {
    let n = 0

    this.items.forEach((i) => {
      n = n + i.quantity
    })

    return n
  }

  public addAttribute(key: string, value: string) {
    this.attributes.push(new Attribute({ key, value }))
  }

  public setAttributes(attributes: Attribute[]) {
    this.attributes = attributes
  }

  public hasAttribute(key: string, value: string) {
    return this.attributes.some((a) => a.key === key && a.value === value)
  }

  public toJSON() {
    return Object.assign({}, this)
  }

  static fromJSON(data: any) {
    const c = new Cart({})

    c.checkoutId = data.checkoutId
    c.items = data.items
    c.subTotalPrice = data.subTotalPrice
    c.totalPrice = data.totalPrice
    c.webUrl = data.webUrl
    c.attributes = data.attributes
    c.isCompleted = data.isCompleted
    c.currency = data.currency

    return c
  }
}
