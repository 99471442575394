import { MutationTree } from 'vuex'
import { AuthState } from './types'
import Customer, { CustomerAccessToken } from '~/entities/Customer'

export const mutations: MutationTree<AuthState> = {
  AUTHENTICATED(state: AuthState, authenticated: boolean) {
    state.authenticated = authenticated
  },
  CUSTOMER(state: AuthState, customer: Customer) {
    state.customer = customer
  },
  TOKEN(state: AuthState, token: CustomerAccessToken) {
    state.token = token
  },
  LOADING(state: AuthState, loading: boolean) {
    state.loading = loading
  },
  ERRORS(state: AuthState, errors: Error[]) {
    state.errors = errors
  },
  CLEAR_ERRORS(state: AuthState) {
    state.errors = []
  },
}
