import axios from 'axios'

class PrismicProductService {
  baseUrl: string
  headers: any

  constructor() {
    if (!process.env.PRISMIC_GRAPHQL_URL) {
      throw new Error('Missing env var PRISMIC_GRAPHQL_URL')
    }

    if (!process.env.PRISMIC_ACCESS_TOKEN) {
      throw new Error('Missing env var PRISMIC_ACCESS_TOKEN')
    }

    this.baseUrl = process.env.PRISMIC_GRAPHQL_URL
    this.headers = {
      Authorization: `token ${process.env.PRISMIC_ACCESS_TOKEN}`,
      Accept: 'application/json',
    }
  }

  setPrismicRefs(ref: string) {
    this.headers['Prismic-Ref'] = ref
  }

  getProductByShopifyId(id: string, lang: string) {
    const q = `{
      allProducts(where: {shopify_id: "${id}"}, lang: "${lang}"){
        edges{
          node{
            _meta {
              uid
            }
            product_name
            product_type
            colors {
              color_thumbnail
              color_name
              image_principale
              image_principale_cdn
            }
          }
        }
      }
    }`

    return axios
      .get(this.baseUrl, {
        headers: { ...this.headers },
        params: {
          query: q,
        },
      })
      .then((r) => {
        if (r.status === 200 && r.data.data && r.data.data.allProducts) {
          return r.data.data.allProducts
        }

        throw new Error(JSON.stringify(r))
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
      })
  }
}

export default new PrismicProductService()
