export interface QueryParametersInterface {
  product?: string
  version?: number
  color?: string
  size?: string
  note?: string
  pageNumber?: number
  pageSize?: number
  hasComment?: boolean
}

export class Parameter {
  name: string
  value = ''

  constructor(name: string, value?: string) {
    this.name = name

    if (value) {
      this.value = value
    }
  }

  addToQuery(u: URL) {
    u.searchParams.set(this.name, this.value)
  }
}

export class ArrayParameter extends Parameter {
  array: (string | number)[] = []

  constructor(name: string, value: (string | number)[]) {
    super(name)
    this.array = value
  }

  addToQuery(u: URL) {
    for (let i = 0; i < this.array.length; i++) {
      u.searchParams.append(this.name, this.array[i].toString())
    }
  }
}
export default class Query {
  private baseURL: string
  private endpoint: string | null = ''
  private parameters: Parameter[] = []

  constructor(
    baseURL: string,
    endpoint?: string,
    data?: QueryParametersInterface
  ) {
    this.baseURL = baseURL

    this.endpoint = endpoint || null

    if (data) {
      if (data.product) {
        this.parameters.push(new Parameter('product', data.product))
      }

      if (data.version) {
        this.parameters.push(new Parameter('version', data.version.toString()))
      }

      if (data.color) {
        this.parameters.push(new Parameter('color', data.color))
      }

      if (data.size) {
        this.parameters.push(new Parameter('size', data.size))
      }

      if (data.note) {
        this.parameters.push(new Parameter('note', data.note))
      }

      if (data.pageNumber) {
        this.parameters.push(
          new Parameter('pageNumber', data.pageNumber.toString())
        )
      }

      if (data.pageSize) {
        this.parameters.push(
          new Parameter('pageSize', data.pageSize.toString())
        )
      }

      if (data.hasComment) {
        this.parameters.push(
          new Parameter('comment', data.hasComment ? 'true' : 'false')
        )
      }
    }
  }

  toQuery(): string {
    const query = new URL(
      `${this.baseURL}${this.endpoint ? this.endpoint : ''}`
    )

    this.parameters.forEach((criteria) => {
      criteria.addToQuery(query)
    })

    return query.toString()
  }
}
