import ipAddressService from './ipAddressService'
import accountManagerService from './accountManagerService'
import { ErrorLogger } from '@/plugins/errorLogger'

class MultipassLoginService {
  async buildMultipassLoginUrl(
    email: string,
    checkoutUrl: string,
    withIP = false
  ): Promise<string> {
    try {
      let ipAddress = ''
      if (withIP) {
        const ipAddressResponse = await ipAddressService.getIpAddress()
        if (!ipAddressResponse.data || !ipAddressResponse.data.ip) {
          throw new Error('Error while getting IP address')
        }

        ipAddress = ipAddressResponse.data.ip
      }

      const tokenResponse = await accountManagerService.generateMultipassToken(
        email,
        checkoutUrl,
        ipAddress
      )

      if (
        !tokenResponse.data ||
        !tokenResponse.data.data ||
        !tokenResponse.data.data.token
      ) {
        throw new Error('Error on multipass token generation response')
      }

      return Promise.resolve(
        `${process.env.SHOPIFY_URL}/account/login/multipass/${tokenResponse.data.data.token}`
      )
    } catch (err) {
      if (err instanceof Error) {
        ErrorLogger.error(err, {
          tags: {
            entity: 'MultipassLoginService',
          },
          extra: {
            data: JSON.stringify(err),
          },
        })
      }
    }
    return Promise.reject(checkoutUrl)
  }
}

export default new MultipassLoginService()
