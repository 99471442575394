import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '../types'

export interface BehaviorState {
  scroll: boolean
  morphoSelected: { [key: string]: number }
  objectToClose: Function[]
  sliceToHide: string[]
  fixHeader: boolean
}

export const state: BehaviorState = {
  scroll: false,
  morphoSelected: {},
  objectToClose: [],
  sliceToHide: [],
  fixHeader: true,
}

export const getters: GetterTree<BehaviorState, RootState> = {
  scroll: (state) => state.scroll,
  morphoSelected: (state) => state.morphoSelected,
  objectToClose: (state) => state.objectToClose,
  sliceToHide: (state) => state.sliceToHide,
  fixHeader: (state) => state.fixHeader,
}

export const mutations: MutationTree<BehaviorState> = {
  SET_SCROLL: (state: BehaviorState, is: boolean) => {
    state.scroll = is
  },
  SET_MORPHO_SELECTED: (
    state: BehaviorState,
    morpho: { uid: string; index: number }
  ) => {
    state.morphoSelected[morpho.uid] = morpho.index
  },
  DELETE_MORPHO_SELECTED: (state: BehaviorState, uid: string) => {
    delete state.morphoSelected[uid]
  },
  ADD_OBJECT_TO_CLOSE: (state: BehaviorState, f: Function) => {
    state.objectToClose.push(f)
  },
  CLEAN_OBJECT_TO_CLOSE: (state: BehaviorState) => {
    state.objectToClose = []
  },
  ADD_SLICE_TO_HIDE: (state: BehaviorState, slice: string) => {
    state.sliceToHide.push(slice)
  },
  CLEAN_SLICE_TO_HIDE: (state: BehaviorState) => {
    state.sliceToHide = []
  },
  SET_FIX_HEADER: (state: BehaviorState, is: boolean) => {
    state.fixHeader = is
  },
}

export const actions: ActionTree<BehaviorState, RootState> = {
  closeObjects({ state, commit }) {
    for (const f of state.objectToClose) {
      f()
    }

    commit('CLEAN_OBJECT_TO_CLOSE')
  },
}

const namespaced = true

export const behavior: Module<BehaviorState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
